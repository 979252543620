import * as React from "react"
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Search from "../../../components/SearchContainer";
import PageDetails from "../../../components/PageDetails";
import {useEffect} from "react";

let isLocal = ['dev', 'development'].includes(process.env.NODE_ENV);
let fr_url = (isLocal ? "localhost:8000" : process.env.FR_URL) + "/fr/recherche/";
let en_url = (isLocal ? "localhost:8000" : process.env.EN_URL) + "/en/search/";

const SearchPageEn = () => {
    useEffect(() => {
        let windowUrl = new URL(window.location.href);
        let url = new URL(fr_url);
        if (windowUrl.searchParams.has("term")) {
            let langLink = document.getElementById("lang_link");
            let href = langLink.getAttribute("href");
            url.searchParams.set("term", windowUrl.searchParams.get("term"))
            langLink.href = url;
        }
    }, [])

    return (<>
            <Header title={"Search job opportunities at our Canadian offices abroad | Global Affairs Canada"}
                    description={"Search and filter all the job opportunities at our Canadian offices abroad."}
                    lang={"EN"}
                    langLink={fr_url}
                    breadcrumbs={[{text: "Search", url: en_url}]}
                    bodyClasses={"page-type-search page-search"}/>
            <main property="mainContentOfPage" resource="#wb-main" className="container pb-5" typeof="WebPageElement">
                <h1>Search for job opportunities at our Canadian offices abroad</h1>
                <p>What we do matters. We define, shape and advance Canada’s interests and values in a complex and
                    evolving global environment. We manage diplomatic relations, promote international trade and
                    provide
                    consular support to Canadians. We lead international development, humanitarian, and peace and
                    security assistance efforts. We also contribute to national security and the development of
                    international law.</p>
                <h2>Search jobs</h2>
                <Search lang={"EN"}/>
                <PageDetails lang={"EN"} dateModified={"2022-01-05"}/>
            </main>
            <Footer lang={"EN"}/>
        </>)
}

export default SearchPageEn;
